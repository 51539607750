import { API_GET } from '../../../utils/api/API';

export const getPdfDownloadUrls = async ({ locationNumber, assessmentId }) => {
  try {
    const response = await API_GET(
      `${process.env.REACT_APP_SERVER_URL}/assessment/lvr/pdf/download/${locationNumber}/${assessmentId}`,
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getAssessments = async ({ locationNumber, program = null }) => {
  try {
    const response = await API_GET(
      `${process.env.REACT_APP_SERVER_URL}/assessment/${locationNumber}${
        program ? `?program=${program}` : ''
      }`,
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

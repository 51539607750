import { useEffect, useState } from 'react';
import { API_GET } from '../../../../../utils/api/API';
import useSelectedLocation from '../../../../../utils/hooks/useSelectedLocation';
import VisitsCard from './VisitsCard';

import './Visits.scss';

const Visits = () => {
  const { locationNumber } = useSelectedLocation();
  const [content, setContent] = useState({});

  useEffect(() => {
    if (!locationNumber) return;

    const getVisitsData = async () => {
      const path = `${process.env.REACT_APP_SERVER_URL}/assessment/lvr/visit-details/${locationNumber}`;
      try {
        const { data } = await API_GET(path);
        setContent({
          lastVisit: data?.lastAssessmentTimestamp?.split('T')?.[0] || 'Never',
          visitsCompleted: parseInt(data?.currentMonthCount, 10) || '0',
        });
      } catch (err) {
        console.log('err', err);
      }
    };

    getVisitsData();
  }, [locationNumber]);

  return <VisitsCard data-testid="visits-visits-card" content={content} />;
};

export default Visits;

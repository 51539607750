export const isValidDate = (date) => !Number.isNaN(date.getTime());

export const mergeDateAndTime = (dateIso, timeIso) => {
  // Parse the date and time from the ISO strings
  const date = new Date(dateIso);
  const time = new Date(timeIso);

  // Check if the input dates are valid
  if (!isValidDate(date) || !isValidDate(time)) {
    throw new Error('Invalid date or time provided.');
  }

  // Merge the date and time
  date.setHours(time.getHours());
  date.setMinutes(time.getMinutes());
  date.setSeconds(time.getSeconds());
  date.setMilliseconds(time.getMilliseconds());

  // Convert to ISO string and return
  return date.toISOString();
};

/**
 * Processes and flattens a collection of questions by category.
 * @param {Array} questionsByCategory - Array of questions categorized.
 * @returns {Array} Flattened array of questions with additional metadata.
 */
export const getFlattenedQuestions = (questionsByCategory) => {
  // Initialize a map to store flattened questions
  const allQuestions = new Map();

  /**
   * Processes the choices within a question, mapping subQuestionIds to internalIds.
   * @param {Array} choices - Array of choices in a question.
   * @param {Map} subQuestionsMap - Map of subQuestions for quick lookup.
   * @returns {Array} Processed array of choices with additional identifiers.
   */
  const processChoices = (question, subQuestionsMap) => {
    const { effectiveVersionId, internalId, questionData } = question;
    return questionData.choices.map((choice) => {
      // Skip processing if there are no subQuestionIds
      if (!choice.subQuestionIds) return choice;

      // Arrays to store processed identifiers
      const subQuestionInternalIds = [];
      const responseUniqueIdentifiers = [];

      // Populate the identifier arrays with corresponding subQuestion data
      choice.subQuestionIds.forEach((subQuestionId) => {
        const subQuestion = subQuestionsMap.get(subQuestionId);
        if (subQuestion) {
          subQuestionInternalIds.push(subQuestion.internalId);
          responseUniqueIdentifiers.push(
            `${subQuestion.effectiveVersionId}-${subQuestion.internalId}_${effectiveVersionId}-${internalId}`,
          );
        }
      });

      // Return the choice with added identifiers
      return {
        ...choice,
        subQuestionInternalIds,
        responseUniqueIdentifiers,
      };
    });
  };

  /**
   * Creates data for sub-questions, linking them to their parent questions.
   * @param {Object} question - The current subQuestion.
   * @param {Object} parentQuestion - The parent question of the current subQuestion.
   * @returns {Object} SubQuestion data linked to parent question.
   */
  const createSubQuestionData = (question, parentQuestion) => {
    return parentQuestion
      ? {
          parentQuestionInternalId: `${parentQuestion.internalId}`,
          parentResponseUniqueIdentifier: `${parentQuestion.effectiveVersionId}-${parentQuestion.internalId}`,
        }
      : {};
  };

  /**
   * Recursively processes each question, adding it to the allQuestions map.
   * @param {Object} question - The question to process.
   * @param {boolean} isRootQuestion - Flag to indicate if the question is a root question.
   * @param {Object} parentQuestion - The parent question, if present.
   */
  const processQuestion = (question, isRootQuestion, parentQuestion) => {
    const { effectiveVersionId, internalId, questionData } = question;
    const responseUniqueIdentifier = parentQuestion
      ? `${effectiveVersionId}-${internalId}_${parentQuestion.effectiveVersionId}-${parentQuestion.internalId}`
      : `${effectiveVersionId}-${internalId}`;

    // Process choices if they exist
    if (questionData?.choices?.length && questionData?.subQuestions?.length) {
      const subQuestionsMap = new Map(
        questionData.subQuestions.map((sq) => [sq.subQuestionId, sq]),
      );
      questionData.choices = processChoices(question, subQuestionsMap);
    }

    // Create subQuestion data linking to parent question
    const subQuestionData = createSubQuestionData(question, parentQuestion);

    // Construct the new question entry with additional metadata
    const newEntry = {
      ...question,
      responseUniqueIdentifier,
      questionData: {
        ...questionData,
        subQuestions: [],
        supplementaryData: {
          responseUniqueIdentifier,
          subQuestionData,
        },
      },
      rootQuestion: isRootQuestion,
    };

    // Add the new entry to the allQuestions map
    allQuestions.set(responseUniqueIdentifier, newEntry);

    // Process subQuestions recursively
    questionData?.subQuestions?.forEach((subQuestion) =>
      processQuestion(subQuestion, false, question),
    );
  };

  // Start the processing of questions
  questionsByCategory.forEach((question) => processQuestion(question, true));

  // Convert the map of questions to an array and return
  return Array.from(allQuestions.values());
};

import { useEffect, useState } from 'react';
import { useHistory, withRouter, useParams } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import {
  Button,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Typography,
  Dropdown,
  LoadingIndicator,
} from 'cfa-react-components';
import I18n from '../../../../i18n/utils';
import Visits from './components/Visits/Visits';
import Staffing from './components/Staffing/Staffing';
import useSelectedLocation from '../../../utils/hooks/useSelectedLocation';
import {
  deleteAutoSaveAssessment,
  getAutoSaveAssessments,
} from '../../../components/Assessment/services/autoSaveApi';
import PdfDownload from '../../../components/Assessment/PdfDownload/PdfDownload';
import useLvrAccess from '../../../utils/hooks/useLvrAccess';

import './LicenseeVisitReport.scss';
import FAASCard from './components/FastAndAccurateService/FAASCard';
import useLvrAssessment from '../../../utils/hooks/useLvrAssessment';
import TopFocusArea from './components/TopFocusArea/TopFocusArea';
import OCNotes from './components/OCNotes/OCNotes';
import PreviousVisitFindingsCard from './components/PreviousVisitFindings/PreviousVisitFindingsCard';
import RestaurantSalesCard from './components/RestaurantSalesWidget/RestaurantSalesCard';

const LicenseeVisitReport = ({ reports = [], isLoading = true }) => {
  const history = useHistory();
  const { assessmentId } = useParams();
  const { hasLvrFormAccess, hasOCNotesAccess } = useLvrAccess();
  const { locationNumber, location } = useSelectedLocation();
  const { findings, setLvrHistory, isFetchingFindings } = useLvrAssessment();

  const [autoSaveId, setAutoSaveId] = useState(null);
  const [selectedReport, setSelectedReport] = useState(null);
  const [showConfirmStartModal, setShowConfirmStartModal] = useState(false);

  useEffect(() => {
    if (reports.length > 0) {
      const report = assessmentId
        ? reports.find((r) => r.assessmentId === Number(assessmentId))
        : reports[0];
      setSelectedReport(report);
      setLvrHistory(report);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports]);

  useEffect(() => {
    if (!locationNumber) return;
    const getAutoSaveId = async () => {
      const { data } = await getAutoSaveAssessments({ locationNumber });
      if (data.length) {
        setAutoSaveId(data[0].questionnaireId);
      }
    };

    getAutoSaveId();
  }, [locationNumber]);

  return isLoading ? (
    <LoadingIndicator variant="page" />
  ) : (
    <>
      <Row
        data-testid="licensee-visit-report-container-row"
        style={{ marginBottom: '2rem' }}
      >
        <Col
          data-testid="licensee-visit-report-container-col"
          lg="9"
          md="8"
          xs="12"
          style={{ marginBottom: '1rem' }}
        >
          <Typography
            data-testid="licensee-visit-report-page-title"
            variant="h3"
            align="left"
          >
            {I18n.t('APP_ASSESSMENT_LANDING_PAGE_TITLE')}
          </Typography>
          <Typography
            data-testid="licensee-visit-report-page-description"
            align="left"
            gutterBottom
            style={{ fontStyle: 'italic', color: '#5B6770' }}
          >
            Widgets on this page display data based on the date selected in the
            top right corner drop down.
          </Typography>
          <div
            className="assessment-landing__button-group"
            data-testid="licensee-visit-report-button-group"
          >
            {hasLvrFormAccess && (
              <Button
                data-testid="licensee-visit-report-start-report-button"
                size="md"
                color="secondary"
                onClick={() => setShowConfirmStartModal(true)}
              >
                {I18n.t('APP_ASSESSMENT_LANDING_START_REPORT')}
              </Button>
            )}
            {reports.length > 0 && (
              <PdfDownload
                data-testid="licensee-visit-report-pdf-download"
                locationNumber={locationNumber}
                reports={reports}
              />
            )}
          </div>
        </Col>
        <Col
          data-testid="licensee-visit-report-date-dropdown-col"
          lg={{ span: 3 }}
          md="4"
          xs="12"
        >
          <Dropdown
            data-testid="licensee-visit-report-date-dropdown"
            compact
            fullWidth
            label="Date"
            disabled={!selectedReport}
            value={selectedReport}
            onChange={(newValue) => {
              setSelectedReport(newValue);
              setLvrHistory(newValue);
            }}
            getOptionId={(option) => option.id}
            options={reports}
            renderOption={(option) => option.date}
          />
        </Col>
      </Row>
      <div className="mb-4 mt-4">
        <Row style={{ marginBottom: '2rem' }}>
          <Col className="visits-column" lg={4}>
            <Visits />
          </Col>
          <Col md={12} lg={8}>
            <Staffing data-testid="licensee-visit-report-staffing" />
          </Col>
        </Row>
        <Row
          style={{
            marginBottom: '2rem',
          }}
        >
          <Col lg={12}>
            <RestaurantSalesCard />
          </Col>
        </Row>
        <Row
          data-testid="licensee-visit-report-staffing-row"
          style={{
            marginBottom: '2rem',
          }}
        >
          <Col lg={5} className="top-focus-area-column">
            <TopFocusArea
              findings={findings}
              isLoading={isFetchingFindings}
              selectedReport={selectedReport}
            />
          </Col>
          <Col lg={7}>
            <PreviousVisitFindingsCard />
          </Col>
        </Row>
        <Row style={{ marginBottom: '2rem' }}>
          <Col className="oc-notes-column" lg={5} md={12}>
            {hasOCNotesAccess ? (
              <OCNotes assessmentId={selectedReport?.assessmentId} />
            ) : (
              <OCNotes assessmentId={selectedReport?.assessmentId} noDataCard />
            )}
          </Col>
          <Col lg={7}>
            <FAASCard assessmentId={selectedReport?.assessmentId} />
          </Col>
        </Row>
      </div>

      <Modal
        data-testid="licensee-visit-report-show-confirm-start-modal"
        show={showConfirmStartModal}
        size="lg"
        onClose={() => setShowConfirmStartModal(false)}
      >
        <ModalHeader data-testid="licensee-visit-report-show-confirm-start-modal-header" />
        <ModalBody data-testid="licensee-visit-report-show-confirm-start-modal-body">
          <Typography
            data-testid="licensee-visit-report-performing-lvr-for-following-location-typography"
            variant="h4"
            fontWeight="regular"
            style={{ color: '#5B6770' }}
            gutterBottom
          >
            You are performing the LVR for the following location:
          </Typography>
          <Typography
            data-testid="licensee-visit-report-location-number-name-typography"
            fontWeight="bold"
            variant="h3"
            color="default" // TODO need to revert the overwrite for the default color of the cfa design system in APP.scss, and then update app where black is needed instead of default
            style={{ color: '#5B6770' }}
          >
            {locationNumber} : {location?.locationName}
          </Typography>
        </ModalBody>
        <ModalFooter data-testid="licensee-visit-report-show-confirm-start-modal-footer">
          {autoSaveId && (
            <Button
              data-testid="licensee-visit-report-continue-with-previous-lvr-button"
              color="secondary"
              onClick={() =>
                history.push(
                  `/${locationNumber}/licensee-visit-report-form?autoSaveId=${autoSaveId}`,
                )
              }
            >
              Continue with Previous LVR
            </Button>
          )}
          <Button
            data-testid="licensee-visit-report-start-new-lvr-button"
            color="secondary"
            variant={autoSaveId ? 'outlined' : 'filled'}
            onClick={async () => {
              await deleteAutoSaveAssessment({
                locationNumber,
              });
              history.push(`/${locationNumber}/licensee-visit-report-form`);
            }}
            fullWidth={false}
          >
            Start New LVR
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default withOktaAuth(withRouter(LicenseeVisitReport));

import { useEffect, useState } from 'react';
import TabLayout from '../../components/Layout/TabLayout/TabLayout';
import LicenseeVisitReport from './LicenseeVisitReport/LicenseeVisitReport';
import AllFindings from './AllFindings/AllFindings';
import AssessmentData from './AssessmentData/AssessmentData';
import { getAssessments } from '../../components/Assessment/services/pdfDownloadApi';
import { transformAndFilterReportsData } from '../../components/Assessment/PdfDownload/utils/dataUtils';
import useSelectedLocation from '../../utils/hooks/useSelectedLocation';

const LicenseeVisitReportDashboard = () => {
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { locationNumber } = useSelectedLocation();

  useEffect(() => {
    if (!locationNumber) return;
    const getAssessmentReports = async () => {
      try {
        const { data } = await getAssessments({
          locationNumber,
          program: 'LVR',
        });
        const allReports = transformAndFilterReportsData(data, false);
        setReports(allReports);
      } catch (e) {
        console.error('LVR: error fetching reports', e.message);
      } finally {
        setIsLoading(false);
      }
    };

    getAssessmentReports();
  }, [locationNumber]);

  const LVR_TABS = {
    LICENSEEVISITREPORT: {
      label: 'Licensee Visit Report',
      basePathBuilder: (path) => path,
      component: (
        <LicenseeVisitReport
          data-testid="licensee-visit-report-dashboard-licensee-visit-report"
          reports={reports}
          isLoading={isLoading}
        />
      ),
    },
    ALLFINDINGS: {
      label: 'All Findings',
      basePathBuilder: (path) => `${path}/findings`,
      component: (
        <AllFindings reports={reports} isFetchingReports={isLoading} />
      ),
      optPathParam: 'id',
    },
    ASSESSMENTDATA: {
      label: 'Assessment Data',
      basePathBuilder: (path) => `${path}/assessment-data`,
      component: (
        <AssessmentData data-testid="licensee-visit-report-dashboard-assessment-data" />
      ),
    },
  };

  return (
    <TabLayout
      data-testid="licensee-visit-report-dashboard-tab-layout"
      title="Licensee Visit Report Dashboard"
      baseUrl="/assessment"
      content={LVR_TABS}
    />
  );
};

export default LicenseeVisitReportDashboard;

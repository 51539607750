import {
  Card,
  CardContent,
  Icon,
  Row,
  Col,
  Typography,
} from 'cfa-react-components';
import { Calendar } from '@cfa-icons/system';
import LvrWidgetTitle from '../../LicenseeVisitReportWidgetTitle';

import './Visits.scss';

const VisitsCard = ({ content }) => {
  const { lastVisit, visitsCompleted } = content;
  return (
    <Card
      className="assessment-card visits-card"
      data-testid="visits-card-assessment-card"
      style={{
        height: '100%',
      }}
    >
      <CardContent
        className="assessment-card__content"
        data-testid="visits-card-assessment-card-content"
      >
        <LvrWidgetTitle
          title="Visits"
          paddingLeft="0"
          data-testid="visits-card-assessment-card-header"
        />
        <Row
          style={{ diplay: 'flex', justifyContent: 'center' }}
          data-testid="visits-card-assessment-card-row"
        >
          <div className="visits-card__container">
            <div
              className="visits-card__last-visit-container"
              data-testid="visits-card-last-visit-container"
              style={{ marginBottom: '3rem' }}
            >
              <Icon
                icon={Calendar}
                size="xl"
                className="visits-card__calendar"
                data-testid="visits-card-calendar-icon"
              />
              {lastVisit && (
                <div
                  className="visits-card__last-visit-subtitle-container"
                  data-testid="visits-card-last-visit-subtitle-container"
                >
                  <Typography
                    className="visits-card__visit-subtitle"
                    data-testid="visits-card-last-visit-subtitle"
                    variant="h4"
                    fontWeight="regular"
                  >
                    Last Visit
                  </Typography>
                  <Typography
                    style={{ marginLeft: '0.5rem' }}
                    data-testid="visits-card-last-visit"
                    fontWeight="bold"
                    variant="h4"
                  >
                    {lastVisit}
                  </Typography>
                </div>
              )}
            </div>
            {visitsCompleted && (
              <div
                className="visits-card__subtitle-container"
                data-testid="visits-card-visits-completed-subtitle-container"
              >
                <Typography
                  className="visits-card__subtitle"
                  data-testid="visits-card-visits-completed-subtitle"
                  style={{
                    marginBottom: '0.15em',
                  }}
                >
                  Total visits this month
                </Typography>
                <Typography
                  data-testid="visits-card-visits-completed"
                  fontWeight="bold"
                >
                  {visitsCompleted} visit(s) completed
                </Typography>
              </div>
            )}
          </div>
        </Row>
      </CardContent>
    </Card>
  );
};

export default VisitsCard;
